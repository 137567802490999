import React from "react";
import { projects } from "./database";
import ProjectCard from "./ProjectCard";

function Projects() {
  return (
    <div className="bg-gray-100 pr-20 pl-20 sm:pr-40 sm:pl-40 w-full flex flex-col">
      <h2
        id="Projects"
        className=" text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center font-black leading-7 md:leading-10 pt-12 pb-12 text-indigo-700"
      >
        Projects
      </h2>
      <div className="flex flex-col w-full">
        {projects.map((project, index) => (
          <ProjectCard index={index} project={project} />
        ))}
      </div>
      <a className="mx-auto pb-10" href="#top">
        Back to top
      </a>
    </div>
  );
}

export default Projects;
