
import FANDOM from "../assets/fandomss.png";
import MovieRater from "../assets/MovieRater.png";
import UrbanWalls from "../assets/UrbanWalls.png";
import Brewery from "../assets/Brewery.png";
import PluggedN from "../assets/Pluggedn.png";
import Stockodds from "../assets/Stockodds.png"
export const projects = [
  {
    name: "StockOdds.us",
    stack: "React / Java",
    media: Stockodds,
    Github: "https://github.com/HaleemH/Plugged-N",
    Youtube: "https://youtu.be/fQD32yfXlPI",
    Website: "https://stockodds.us/",
    description:
      "Full-stack app that allows users to create tasks and inquire about available tasks.",
  },
  {
    name: "Plugged-N",
    stack: "React / Rails",
    media: PluggedN,
    Github: "https://github.com/HaleemH/Plugged-N",
    Youtube: "https://youtu.be/fQD32yfXlPI",
    description:
      "Full-stack app that allows users to create tasks and inquire about available tasks.",
  },
  {
    name: "The Brewery Discovery",
    stack: "React / Rails",
    media: Brewery,
    Github: "https://github.com/HaleemH/The-Brewery-Discovery",
    Youtube: "https://youtu.be/t3aAwHM446Q",
    description:
      "Full-stack app that allows users to find breweries and view what's on tap.",
  },
  {
    name: "Urban Walls",
    stack: "React / Sinatra",
    media: UrbanWalls,
    Github: "https://github.com/HaleemH/Urban-Walls-FrontEnd",
    Youtube: "https://youtu.be/dLbxgBmbUWA",
    description:
      "Full-stack app that showcases wall art. Also allows engagement from users.",
  },
  {
    name: "Movie Rater",
    stack: "React ",
    media: MovieRater,
    Github: "https://github.com/HaleemH/phase2Project-Flatiron",
    Youtube: "https://youtu.be/bRVF_wfN83s",
    description:
      "Web app that allows users to view and rate movies added by other users.",
  },
  {
    name: "Fandom",
    stack: "Vanilla Javascript",
    media: FANDOM,
    Github: "https://github.com/HaleemH/Phase-1-project",
    Youtube: "https://youtu.be/MhBrXYLBbjI",
    description:
      "App that allows users to view team information and vote for their favorite.",
  },
];

export const technologies = [
  {
    name: "CSS3",
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg",
  },
  {
    name: "HTML5",
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg",
  },
  {
    name: "JavaScript",
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
  },
  {
    name: "React",
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg",
  },
  {
    name: "Ruby",
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/ruby/ruby-original.svg",
  },
  {
    name: "Rails",
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/rails/rails-original-wordmark.svg",
  },
  {
    name: "SQLite",
    img: "https://www.vectorlogo.zone/logos/sqlite/sqlite-icon.svg",
  },
  {
    name: "PostgreSQL",
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg",
  },
  {
    name: "Node.js",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Node.js_logo_2015.svg/591px-Node.js_logo_2015.svg.png?20150315010906",
  },
];