import React, { useState, useEffect } from "react";
import Jumbo from "./components/Jumbo";
import Navbar from "./components/Navbar";
import Technologies from "./components/Technologies";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import { FaBars } from "react-icons/fa";

import "./App.css";

function App() {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    console.log(window.innerWidth);
  }, [openMenu]);
  return (
    <div className="flex relative bg-gray-100" id="top">
      {openMenu ? (
        <Navbar openMenu={openMenu} setOpenMenu={setOpenMenu} />
      ) : null}
      <div>
        {openMenu ? null : (
          <button
            onClick={() => setOpenMenu(true)}
            className="text-2xl z-50 absolute top-8 left-10 sticky "
          >
            <FaBars />
          </button>
        )}
        <Jumbo />
        <Technologies />
        <Projects />
        {parseInt(window.innerWidth) < 768 ? <Contact /> : null}
      </div>
    </div>
  );
}

export default App;
