import React from 'react'
import { FaRegEnvelope, FaGithubSquare, FaLinkedin } from "react-icons/fa";
function Contact() {
  return (
    <div className="">
      
      
      <h2 id="Contact" className=" text-lg text-center   text-indigo-700">
        Contact Me:
      </h2>
      <div className="flex items-center justify-center text-3xl	p-6">
        <a
          className="flex flex-col  p-2 items-center justify-evenly hover:scale-125"
          href="mailto:hhamidbiz@gmail.com"
        >
          <FaRegEnvelope />
        </a>
        <a
          className="flex flex-col p-2 items-center justify-evenly hover:scale-125"
          href=" https://github.com/HaleemH"
          target="_blank"
        >
          <FaGithubSquare />
        </a>
        <a
          className="flex flex-col p-2 items-center justify-evenly hover:scale-125"
          href=" https://www.linkedin.com/in/haleemhamid/"
          target="_blank"
        >
          <FaLinkedin />
        </a>
      </div>
      <div className="text-sm" align="center">
        <br />
        Haleem Hamid
        <br />
        Los Angeles, CA
        <br />
        (310)415-1620
      </div>
    </div>

  );
}

export default Contact