import React from "react";
import avatar from "../assets/avatar.png"

function Jumbo() {
  return (
    <div className="bg-gray-100 min-w-full">
      <div className="container mx-auto flex flex-col items-center py-12 sm:py-24">
        <div className="w-11/12 sm:w-2/3 flex justify-center items-center flex-col  mb-5 sm:mb-10">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-gray-800 font-black leading-7 md:leading-10 pb-4">
            Welcome to my
            <span className="text-indigo-700 "> PORTFOLIO </span>!
          </h1>
          <img
            className=" justify-center items-center rounded-full shadow-2xl max-w-xs"
            src={avatar}
            alt="avatar"
          />
          <p className="mt-5 sm:mt-10 lg:w-10/12 text-gray-500 font-normal text-center text-sm sm:text-lg pt-6 pb-6">
            My name is Haleem Hamid, I'm a Software Developer and recent
            graduate of Flatiron School's Software Engineering Program. I have a
            pasion for learning and working with new technologies. I’m
            enjoy building front-end user interfaces, as well as
            designing and maintaining relational databases. Since
            graduating I've been focusing on learning Node / Python
            while honing my problem-solving skills.
          </p>
          <a href="#Technology">
            <button className="pt-12 animate-bounce ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-indigo-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </a>
        </div>
        {/* <div class="flex justify-center items-center">
        <button class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 lg:text-xl lg:font-bold  rounded text-white px-4 sm:px-10 border border-indigo-700 py-2 sm:py-4 text-sm">Get Started</button>
        <button class="ml-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 bg-transparent transition duration-150 ease-in-out hover:border-indigo-600 lg:text-xl lg:font-bold  hover:text-indigo-600 rounded border border-indigo-700 text-indigo-700 px-4 sm:px-10 py-2 sm:py-4 text-sm">Live Demo</button>
      </div> */}
      </div>
    </div>
  );
}

export default Jumbo;

//  <div className=" ">
//     <img src={skyline} alt="background img"/>
//   </div>
