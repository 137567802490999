import React from "react";
import { FaGithub } from "react-icons/fa";

function ProjectCard({ index, project }) {
  let card = (
    <>
      {project.Website ? (
        <a href={project.Website} target="_blank" rel="noreferrer" className="">
          <img
            className="hover:scale-110 shadow-2xl rounded-2xl w-10/12"
            src={project.media}
            alt={project.name}
          />
        </a>
      ) : (
        <a href={project.Youtube} target="_blank" rel="noreferrer" className="">
          <img
            className="hover:scale-110 shadow-2xl rounded-2xl w-10/12"
            src={project.media}
            alt={project.name}
          />
        </a>
      )}
    
      <div className="w-5/12 md:w-8/12 flex p-2  lg:p-6 text-center">
        <p className=" w-full text-indigo-700  text-2xl  font-bold flex flex-col justify-center">
          {project.name}

          <p className="text-sm pt-4 text-gray-500">{project.stack}</p>
          {project.Website ? (
            <a
              className="text-4xl hover:scale-150 py-4 mx-auto "
              href={project.Website}
              target="_blank"
              rel="noreferrer"
            >
              Check it out!
            </a>
          ) : (
            <a
              className="text-4xl hover:scale-150 py-4 mx-auto "
              href={project.Github}
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub />
            </a>
          )}
        </p>
      </div>
    </>
  );
  if (index % 2 === 1) {
    card = (
      <>
        <div className="w-5/12 md:w-8/12 flex lg:p-6 text-center">
          <p className=" w-full text-indigo-700   text-2xl  font-bold flex flex-col justify-center">
            {project.name}

            <p className="text-sm pt-4 text-gray-500">{project.stack}</p>
            {project.Website ? (
              <a
                className="text-4xl hover:scale-150 py-4 mx-auto "
                href={project.Website}
                target="_blank"
                rel="noreferrer"
              >
                Check it out!
              </a>
            ) : (
              <a
                className="text-4xl hover:scale-150 py-4 mx-auto "
                href={project.Github}
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub />
              </a>
            )}
          </p>
        </div>
        <a href={project.Youtube} target="_blank" rel="noreferrer">
          <img
            className="hover:scale-110 shadow-2xl rounded-2xl w-10/12"
            src={project.media}
            alt={project.name}
          />
        </a>
      </>
    );
  }
  // Smaller screens
  if (parseInt(window.innerWidth) < 900) {
    card = (
      <div className="w-full">
        <div className="flex p-2  text-center">
          <p className=" w-full text-indigo-700  text-lg  font-bold flex flex-col justify-center">
            {project.name}

            <a
              className="text-4xl hover:scale-150 py-4 mx-auto "
              href={project.Github}
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub />
            </a>
          </p>
        </div>
        <a href={project.Youtube} target="_blank" rel="noreferrer" className="">
          <img
            className="hover:scale-110 shadow-2xl rounded-2xl w-full"
            src={project.media}
            alt={project.name}
          />
        </a>
      </div>
    );
  }

  return <div className="flex p-5">{card}</div>;
}

export default ProjectCard;
