import React from "react";
import Contact from "./Contact";
import { FaAngleLeft } from "react-icons/fa";

function Navbar({ openMenu, setOpenMenu }) {
  const navContent = (
    <ul onClick={() => setOpenMenu(false)}className="z-20 flex w-full text-3xl items-center py-10 flex-col justify-center bg-white font-bold rounded-2xl shadow-2xl relative">
      <button
        onClick={() => setOpenMenu(false)}
        className=" text-black absolute top-0 left-4 p-4 text-sm "
      >
        <FaAngleLeft />
      </button>
      <li className=" pt-6 text-gray-700 hover:text-gray-900 cursor-pointer text-lg  hover:scale-125 ">
        <a href="#Technology">Technologies</a>
      </li>
      <li className=" py-10 text-gray-700 hover:text-gray-900 cursor-pointer text-lg  hover:scale-125">
        <a href="#Projects">Projects</a>
      </li>
      <Contact />
    </ul>
  );
  return (
    <div className=" fixed top-20 h-fit rounded-2xl ">
      {navContent}
    </div>
  );
}

export default Navbar;
