import React from 'react'
import { technologies } from "./database"
function Technologies() {
  return (
    <div className="bg-gray-100 p-2 ">
      <h2
        id="Technology"
        className=" text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center font-black leading-7 md:leading-10 pt-10 pb-10 text-indigo-700"
      >
        Technologies
      </h2>
      <div className="flex 	justify-evenly	flex-wrap py-10">
        {technologies.map((tech, index) => (
          <img
            key={index}
            className="max-w-10 pb-2 max-h-20 hover:scale-125	hover:animate-ping cursor-pointer"
            src={tech.img}
            alt={tech.name}
          />
        ))}
      </div>
    </div>
  );
}

export default Technologies